import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Logo = () => {
    return (
        <StaticImage
            src="../../assets/images/logo_black-transparent.png"
            width={200}
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            alt="project bridge"
        />
    )
}

export default Logo
